;(function($, factory) {
    // conditional factory logic should be placed here.
    if (typeof Lum == 'undefined') {
        var lmnInterval = setInterval(function(){
            if (typeof Lum != 'undefined') {
                clearInterval(lmnInterval);
                factory($);
            }
        }, 100);
    } else {
        factory($);
    }
})(jQuery, function($) {

    var defaultOptions = {
    };

    /**
     * Loads jQuery Mask
     * 
     * @param  {Function} callback The callback to execute once the mask is loaded.
     * 
     * @return {Void}            
     */
    function includeSlick(callback) {
        if (typeof $ == 'undefined') {
            return setTimeout(function() {
                includeSlick(callback);
            }, 200);
        }

        if (typeof $("").slick != 'undefined') {
            return;
        }
        
        var slickCDN = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.js';
        var styleCDNUrl = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';

        //Lum.loadStyle(styleCDNUrl);
        Lum.loadScriptAsync(slickCDN, callback);
    }

    function applySlick(selector, options) {
        if (typeof $("").slick == 'undefined') {
            return setTimeout(function() {
                applySlick(selector, options);
            }, 200);
        }

        if (typeof options == 'undefined') {
            options = {};
        }

        options = $.extend(defaultOptions, options);

        $(selector).slick(options);
    }

    /**
     * This method initialises parsley if and adds the custom validation
     * as well as the bindings.
     */
    var initSlick = function (selector) {
    };

    /**
     * Keep the file versioned.
     * @return {String}
     */
    var version = function() {
        return '1.0.0';
    };

    window.Lum.Slick = {
        applyOn: applySlick,
        version: version
    };

    window.Lum.slick = applySlick;

    includeSlick(initSlick);
});
