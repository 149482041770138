;(function($, factory) {
    // conditional factory logic should be placed here.
    if (typeof Lum == 'undefined') {
        var lmnInterval = setInterval(function(){
            if (typeof Lum != 'undefined') {
                clearInterval(lmnInterval);
                factory($);
            }
        }, 100);
    } else {
        factory($);
    }
})(jQuery, function($) {

    /**
     * Loads jQuery Mask
     * 
     * @param  {Function} callback The callback to execute once the mask is loaded.
     * 
     * @return {Void}            
     */
    function includeParsley(callback) {
        if (typeof $ == 'undefined') {
            return setTimeout(function() {
                includeParsley(callback);
            }, 200);
        }

        if (typeof window.Parsley != 'undefined') {
            return;
        }

        var parsleyCDN = 'https://cdnjs.cloudflare.com/ajax/libs/parsley.js/2.8.1/parsley.min.js';

        Lum.loadScriptAsync(parsleyCDN, callback);
    }

    /**
     * This function adds the custom validators to the main Parsley object.
     * NOTE: This function should not be used out of this file.
     * We bind the default validations for .lumina-phone and .lumina-date input elements.
     */
    function addValidationMethods() {
        window.Parsley.addValidator('luminaPhone', {
            requirementType: 'regexp',
            validateString: function(value, requirement, inst) {
                //\+[0-9]\ \([0-9][0-9][0-9]\)\ [0-9][0-9][0-9]\-[0-9][0-9][0-9]
                var input = inst['$element']
                var mask = $(input).data('mask-format');
                if (!mask) {
                    mask = "(999) 999-9999";
                }

                mask = mask.replace(/\ /g, '\\ ');
                mask = mask.replace(/\(/g, '\\(');
                mask = mask.replace(/\)/g, '\\)');
                mask = mask.replace(/\+/g, '\\+');
                mask = mask.replace(/\-/g, '\\-');
                mask = mask.replace(/9/g, '[0-9]');

                var regEx = new RegExp(mask, 'ig');

                return regEx.test(value);
            },
            messages: {
                en: 'Please provide the phone in the correct format.'
            }
        });

        window.Parsley.addValidator('luminaDate', {
            requirementType: 'regexp',
            validateString: function(value, requirement, inst) {

                var input = inst['$element']
                var mask = $(input).data('mask-format');
                if (!mask) {
                    mask = "mm/dd/yyyy";
                }

                var separators = ['/', ' ', '-', '.'];
                var separator = '/';

                separators.forEach(function(element) {
                    if (mask.indexOf(element) != -1) {
                        separator = element;
                    }
                });

                var parts = mask.split(separator);
                var res = value.split(separator);
                var dateCreationData = {};

                parts.forEach(function(elem, index) {
                    if (elem.indexOf("m") != -1) {
                        dateCreationData.month = res[index];
                    }

                    if (elem.indexOf("d") != -1) {
                        dateCreationData.day = res[index];
                    }

                    if (elem.indexOf('yy') != -1) {
                        dateCreationData.year = res[index];
                    }
                });

                if (typeof dateCreationData.day == 'undefined' && mask.indexOf('d') == -1) {
                    dateCreationData.day = 1;
                }

                if (typeof dateCreationData.year == 'undefined' && mask.indexOf('yy') == -1) {
                    dateCreationData.year = 2018;
                }

                if (dateCreationData.year < 100) {
                    dateCreationData.year += 2000;
                }

                var testDate = new Date(dateCreationData.year + '-' + dateCreationData.month + '-' + dateCreationData.day);

                return Lum.isDate(testDate);
            },
            messages: {
                en: 'Please provide a valid date.'
            }
        });

        window.Parsley.addValidator('luminaDateMmdd', {
            requirementType: 'regexp',
            validateString: function(value, requirement) {
                var res = value.split('/');
                if (res.length < 2) {
                    return false;
                }

                var now = new Date();
                var year = now.getFullYear();

                if (!res[0] || !res[1]) {
                    return false;
                }

                var testDate = new Date(year + '-' + res[0] + '-' + res[1]);

                return Lum.isDate(testDate);
            },
            messages: {
                en: 'Please provide a valid date.'
            }
        });

        window.Parsley.addValidator('imageTypeValidation', {
            requirementType: 'string',
            validateString: function (value, requirement, parsleyInstance) {

                var file = parsleyInstance.$element[0].files;

                if (file.length == 0) {
                    return true;
                }

                var allowedMimeTypes = requirement.replace(/\s/g, "").split(',');
                return allowedMimeTypes.indexOf(file[0].type) !== -1;

            },
            messages: {
                en: 'Please upload a file in JPG, JPEG, PNG, GIF, TIF format...'
            }
        });

        $.listen('parsley:field:error', function(parsleyField) {
            if (parsleyField.$element.attr('name') == 'upload_logo') {
                $('#input_readonly').removeClass('parsley-success');
                $('#input_readonly').addClass('parsley-error');
            }
        });

        $.listen('parsley:field:success', function(parsleyField) {
            if (parsleyField.$element.attr('name') == 'upload_logo') {
                $('#input_readonly').removeClass('parsley-error');
                $('#input_readonly').addClass('parsley-success');
            }
        });

    }

    /**
     * This method binds the validation on known classes.
     * 
     * @param  {String} trigger Trigger action: submit, keyup, focusout
     * 
     * @return {Void}
     */
    function bindValidationMethods(trigger) {

        if (typeof trigger == 'undefined') {
            trigger = 'keyup';
        }

        $('.lumina-phone').attr('data-parsley-lumina-phone', 'true');
        $('.lumina-phone').attr('data-parsley-trigger', trigger);

        $('.lumina-date').attr('data-parsley-lumina-date', 'true');
        $('.lumina-date').attr('data-parsley-trigger', trigger);

        $('.lumina-date-mmdd').attr('data-parsley-lumina-date-mmdd', 'true');
        $('.lumina-date-mmdd').attr('data-parsley-trigger', trigger);
    }

    /**
     * This method initialises parsley if and adds the custom validation
     * as well as the bindings.
     */
    var initParsley = function (selector) {
        if (typeof window.Parsley == 'undefined') {
            setTimeout(function() {
                initParsley(selector);
            }, 200);

            return;
        }

        if (typeof selector == 'undefined') {
            selector = '.lumina-parsley';
        }

        addValidationMethods();
        bindValidationMethods();

        return $(selector).parsley();
    };

    var applyOn = function (selector, options) {
        if (typeof selector == 'undefined') {
            return;
        }

        if (typeof window.Parsley == 'undefined') {
            setTimeout(function() {
                applyOn(selector);
            }, 200);

            return;
        }

        if (typeof options == 'undefined') {
            options = {};
        }

        return $(selector).parsley(options);
    };

    /**
     * Keep the file versioned.
     * @return {String}
     */
    var version = function() {
        return '1.0.3';
    };

    window.Lum.Parsley = {
        applyOn: applyOn,
        bindValidationMethods:  bindValidationMethods,
        version: version
    };

    includeParsley(initParsley);
});
