;(function($, factory) {
    // conditional factory logic should be placed here.
    factory($);
})(jQuery, function($) {
    /**
     * Check if the given variable is a function.
     * @param  {Function} functionToCheck The variable to be checked.
     * @return {Boolean}                  True if function false otherwise.
     */
    function isFunction(functionToCheck) {
        var getType = {};
        return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
    }

    /**
     * Given a script url loads it and executes the callback.
     * @param  {String}   url      The url of the script to be loaded.
     * @param  {Function} callback The callback to be executed when loaded.
     * @param  {Boolean}           True to load the script async.
     * @return {void}
     */
    function loadScript(url, callback, async) {
        console.log('Loading URL: ' + url);

        if (typeof async == 'undefined') {
            async = false;
        }

        var script = document.createElement("script")
        script.type = "text/javascript";
        script.async = async;

        if (script.readyState){  //IE
            script.onreadystatechange = function(){
                if (script.readyState == "loaded" || script.readyState == "complete") {
                    script.onreadystatechange = null;
                    if (isFunction(callback)) {
                        callback();
                    }
                }
            };
        } else {  //Others
            script.onload = function() {
                if (isFunction(callback)) {
                    callback();
                }
            };
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }

    /**
     * Given a style url loads itin the page.
     * @param  {String}   url      The url of the script to be loaded.
     * @return {void}
     */
    function loadStyle(url) {
        console.log('Loading URL: ' + url);

        var link = document.createElement("link")
        link.type = "text/css";   
        link.rel = "stylesheet";
        link.media = "screen,print";
        link.href = url;

        document.getElementsByTagName("head")[0].appendChild(link);
    }

    /**
     * Given a script url loads it and executes the callback.
     * @param  {String}   url      The url of the script to be loaded.
     * @param  {Function} callback The callback to be executed when loaded.
     * @return {void}
     */
    function loadAsyncScript(url, callback) {
        loadScript(url, callback, true);
    }

    function isDate(d) {
        if (Object.prototype.toString.call(d) === "[object Date]") {
            // it is a date
            if (isNaN(d.getTime())) {  // d.valueOf() could also work
                return false; // date is not valid
            } else {
                return true; // date is valid
            }
        } else {
            return false; // not a date
        }
    }

    /**
     * Keep the file versioned.
     * @return {String}
     */
    var version = function() {
        return '1.0.1';
    };

    var Lum = function () {
        this.loadScript = function(url, callback, async) {
            loadScript(url, callback, async);
        };

        this.loadStyle = function(url) {
            loadStyle(url);
        };

        this.loadScriptAsync = function(url, callback) {
            loadAsyncScript(url, callback);
        };

        this.checkFunction = function(functionToCheck) {
            isFunction(functionToCheck);
        };

        this.isDate = function(dateObj) {
            return isDate(dateObj);
        };

        this.version = function() {
            return version();
        };

        return this;
    };

    window.Lum = new Lum();
});
