;(function($, factory) {
    // conditional factory logic should be placed here.
    if (typeof Lum == 'undefined') {
        var lmnInterval = setInterval(function(){
            if (typeof Lum != 'undefined') {
                clearInterval(lmnInterval);
                factory($);
            }
        }, 100);
    } else {
        factory($);
    }
})(jQuery, function($) {
    var defaultOption = {
        url: "/wp-admin/admin-ajax.php",
        method: "POST"
    };

    var modal = $("#ajax-modal");

    var success = function (data) {
        modal.slideUp(250);
        console.log(data);

        if (typeof data.success == 'undefined') {
            //handle default response (not success not error but wp_send_json)
            return ;
        }

        if (data.success === true) {
            //handle default success response
            return ;
        }

        if (data.success === false) {
            //handle default error response
            return ;
        }
    };

    var error = function (request) {
        modal.slideUp(250);
    };

    var makeCall = function (action, data, additionalOptions) {
        if (typeof additionalOptions == 'undefined') {
            additionalOptions = {};
        }

        data.action = action;

        var reCaptcha = $(".g-token");

        if (reCaptcha.length) {
            data.re_token =  reCaptcha.val();
        }

        var options = {
            data: data,
            success: success,
            error: error
        };

        modal.slideDown(250);
        options = $.extend(options, defaultOption, additionalOptions);

        return $.ajax(options);
    };

    var makeFileCall = function (action, data, additionalOptions) {
        if (typeof additionalOptions == 'undefined') {
            additionalOptions = {};
        }

        data.append('action', action);

        var reCaptcha = $(".g-token");

        if (reCaptcha.length) {
            data.re_token =  reCaptcha.val();
        }

        var options = {
            data: data,
            success: success,
            error: error
        };

        modal.slideDown(250);
        options = $.extend(options, defaultOption, additionalOptions);

        return $.ajax(options);
    };

    /**
     * Keep the file versioned.
     * @return {String}
     */
    var version = function () {
        return '1.0.0';
    };

    window.Lum.Ajax = {
        makeCall: makeCall,
        version: version
    };

    window.Lum.ajax = function (action, data, options) {
        return makeCall(action, data, options);
    };

    window.Lum.ajaxWithFiles = function(action, data, options) {
        return makeFileCall(action, data, options);
    };
});