;(function(factory) {
    // conditional factory logic should be placed here.
    if (typeof Lum == 'undefined' || typeof jQuery == 'undefined') {
        var lmnInterval = setInterval(function(){
            if (typeof Lum != 'undefined' && typeof jQuery != 'undefined') {
                clearInterval(lmnInterval);
                factory(jQuery);
            }
        }, 100);
    } else {
        factory(jQuery);
    }
})(function($) {

    /**
     * Loads bLazy
     * 
     * @param  {Function} callback The callback to execute once bLazy is pulled
     * 
     * @return {Void}
     * 
     * @see Docs {@link http://dinbror.dk/blog/blazy/ | Docs} for how to use bLazy.
     */
    function includeBLazy(callback) {
        if (typeof $ == 'undefined') {
            return setTimeout(function() {
                includeBLazy(callback);
            }, 200);
        }

        var bLazyURL = 'https://cdnjs.cloudflare.com/ajax/libs/blazy/1.8.2/blazy.min.js';

        Lum.loadScriptAsync(bLazyURL, callback);
    }

    /**
     * This method initialises parsley if and adds the custom validation
     * as well as the bindings.
     */
    var initBLazy = function () {
        // Initialize
        var bLazy = new Blazy();
    };

    /**
     * Use onInit in the documentReady function to make sure Blazi has been loaded before
     * executing any new Blazy() comand or if Blazi is throwing errors.
     * 
     * @param {function} callback The callback function to call to check Blazy has been loaded.
     */
    var onBlazyInit = function (callback) {
        if (typeof Blazy == 'undefined') {
            return setTimeout(function() {
                onBlazyInit(callback);
            }, 200);
        }

        if (typeof callback == 'function') {
            callback();
        }
    };

    /**
     * Keep the file versioned.
     * @return {String}
     */
    var version = function() {
        return '1.0.0';
    };

    window.Lum.Blazy = {
        version: version,
        onInit: onBlazyInit
    };

    includeBLazy(initBLazy);
});
