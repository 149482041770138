;(function($, factory) {
    // conditional factory logic should be placed here.
    if (typeof Lum == 'undefined') {
        var lmnInterval = setInterval(function(){
            if (typeof Lum != 'undefined') {
                clearInterval(lmnInterval);
                factory($);
            }
        }, 100);
    } else {
        factory($);
    }
})(jQuery, function($) {

    var defaults = {
        phone: "(999) 999-9999",
        date: "mm/dd/yyyy"
    }

    /**
     * Loads jQuery Mask
     * 
     * @param  {Function} callback The callback to execute once the mask is loaded.
     * 
     * @return {Void}
     */
    function includeJQueryMask(callback) {
        if (typeof $("").mask != 'undefined') {
            return;
        }

        var maskCDNUrl = 'https://cdnjs.cloudflare.com/ajax/libs/jquery.mask/1.14.12/jquery.mask.min.js';

        Lum.loadScriptAsync(maskCDNUrl, callback);
    }

    function maskElement(element, defaultMask) {
        var mask = $(element).data('mask-format');

        if (!mask) {
            mask = defaultMask;
        }

        $(element).mask(mask);
    }

    function maskDates (element) {
        var mask = $(element).data('mask-format');

        if (!mask) {
            mask = defaults.date;
        }

        mask = mask.replace(/m/g, "9");
        mask = mask.replace(/d/g, "9");
        mask = mask.replace(/y/g, "9");

        console.log(mask);

        $(element).mask(mask);
    }

    /**
     * This method initialise the masks on the default components.
     * This method can be called to re-apply masks on different action.
     * 
     * @param  {String} onAction The trigger action: keyup, change, focusout...
     * 
     * @return {Void}
     */
    var lumInitMasks = function(onAction) {
        // we use two different jQuery versions, this is to catch which one has the mask.
        if (typeof $("").mask == 'undefined') {
            if (typeof jQuery("").mask == "undefined") {
                return setTimeout(function() {
                    lumInitMasks(onAction);
                }, 200);
            } else {
                $ = jQuery;
            }
        }

        if (typeof onAction == 'undefined') {
            onAction = 'keyup';
        }

        $('.lumina-phone').unbind(onAction);
        $('.lumina-date').unbind(onAction);
        $(".lumina-date-mmdd").unbind(onAction);

        $(".lumina-phone").each(function(index, elem) {
            maskElement(elem, defaults.phone);
        });
        $(document).on(onAction, '.lumina-phone', function(ev) {
            maskElement(ev.currentTarget, defaults.phone);
        });

        $(".lumina-date").each(function(index, elem) {
            maskDates(elem, defaults.date);
        });
        $(document).on(onAction, ".lumina-date", function(ev) {
            maskDates(ev.currentTarget, defaults.date);
        });

        $(".lumina-date-mmdd").each(function(index, elem) {
            maskDates(elem, 'mm/dd');
        });
        $(document).on(onAction, ".lumina-date-mmdd", function() {
            maskDates(ev.currentTarget, "mm/dd");
        });
    };

    /**
     * This method applies a given mask to a given element.
     * 
     * @param  {String} elementSelector The element selector: #elem-id or .elem-class
     * @param  {String} mask            The mask to be applied: 99-999-9999
     * 
     * @return {Void}
     */
    var lumApplyMask = function(elementSelector, mask) {
        $(elementSelector).mask(mask);
    };

    /**
     * This methods bind the element with a mas on a specific event.
     * For example #phone element with '(999) 999-9999' mas on 'keyup'.
     * 
     * @param  {String} elementSelector The element selector: #elem-id or .elem-class
     * @param  {String} mask            The mas to be applied: 99-999-9999
     * @param  {String} onAction        The trigger action: keyup, change, focusout
     * 
     * @return {Void}
     */
    var lumBindMask = function(elementSelector, mask, onAction) {
        if (typeof onAction == 'undefined') {
            onAction = 'keyup';
        }

        $(elementSelector).unbind(onAction);
        $(document).on(onAction, elementSelector, function(ev) {
            $(ev.currentTarget).mask(mask);
        });
    };

    /**
     * Keep the file versioned.
     * @return {String}
     */
    var version = function () {
        return '1.0.2';
    };

    window.Lum.Mask = {
        bind: lumBindMask,
        apply: lumApplyMask,
        initM: lumInitMasks,
        version: version
    };

    includeJQueryMask(lumInitMasks);
});
