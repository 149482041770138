document.addEventListener('DOMContentLoaded', function() {
    const initialInterval = setInterval(function(){
        if (jQuery && Lum) {
            clearInterval(initialInterval);
            initParentMainThings(jQuery, Lum);
        }
    }, 50);
});

function initParentMainThings($, Lum){
    if($('#header-01').length>0){
        $('body').css('padding-top', $('.site-header').height() + 'px');
    }
    
    mobileButtons();
    fixedMenu();
    lmn_scroll_down_fixed_menu($('header.site-header'));
    lmn_mobile_btn_toggle($('header .btn-mob'));
    lmn_mobile_subcategories_btns();
    lmn_change_product_thumb();
    scroll_down('.full-page-scroll-down');
    filter_results_sticky_menu();

    lmn_close_popup_section();
    lmn_open_popup_section();

    
    $('body').click(function () {
        //document.activeElement.blur();
    });

    $('input, textarea').click(function (event) {
        //event.stopPropagation();
    });

    

    $('.share-btn').on("click", function(e) {
      $(this).lmn_share_popup(e);
    });
    
    $(window).on('load', function(){

        /*
        if($('#contactForm')){
            $('#contactForm').formSubmit();
        }
        
        if($('#requestForm')){
            $('#requestForm').formSubmit();
        }

        $('.footer-subscribe').formSubscribe();
        $('.sidebar-subscribe').formSubscribe();
        $('.subscribe-popup .sbs-form').formSubscribe();
        */

    });

    
}



function mobileButtons(){

    $('#mobMenuBtn').on('click touch', function(e){
        e.preventDefault();
        $('body').toggleClass('mobile');
        $('body').removeClass('search-mobile');
    });

}


function fixedMenu(){
    $(window).on('scroll', function(){
        if($(this).scrollTop()>0){
            $('body').addClass('fix-menu');
        }else{
            $('body').removeClass('fix-menu');
        }
    });
}

function scroll_down(scroll_down){
    $(document).on('click touch', scroll_down, function(e){
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $(this).parent().next().offset().top
         }, 1500);
    });
}


function imageSlider(element, args){
    
    Lum.slick(".single-slider", {
        centerMode: true,
        centerPadding: '0px',
        dots: true,
        arrows:false,
        infinite: true,
        autoplay: true,
        variableWidth: true,        
        autoplaySpeed: 3000,
        pauseOnHover: false,
        speed: 1000,
        easing: 'easeOutElastic',
        slidesToShow: 1,
        adaptiveHeight: true
    });

    $(".single-slider").on('init', function(){
        $(this).addClass('slick-loaded');
    });
    
}
